<template>
    <div class="live-content">
        <div class="tag-item">
            <div class="item-content">
                <img src="../../assets/image/paimingbiaoqian.png" alt="">
                <div class="item-text" @click="goToDetail">复盘数据</div>
            </div>
        </div>
        <div class="resume-title">
            <span>直播复盘</span>
        </div>
        <div class="data-content">
            <div class="data-content-item" v-for="(item,index) in rightData" :key="index"
                 :style="{background:'linear-gradient(135deg,'+ item.color1 + ','+ item.color2 +')'}">
                <div class="middle-box">
                    <div class="top-item">{{item.time}}</div>
                     <div class="bottom-item">{{item.title}}</div>
                    <div class="middle-item">
                        <!-- <div v-if="index == 1">
                            <i class="iconfont" v-if="item.is_leave == 0">&#xe693;</i>
                            <i class="iconfont" v-else>&#xe722;</i>
                        </div> -->
                        <span>{{item.value}}</span>
                    </div>
                   
                </div>
                <div class="icon-box">
                    <i class="iconfont" v-html="item.icon"></i>
                </div>
                <div class="shadow-icon">
                    <i class="iconfont" v-html="item.icon"></i>
                </div>
            </div>
        </div>
        <div class="main-content">
            <div class="content-item left-content">
                <div class="top-title">
                    <img src="../../assets/image/student/tag_left.png" alt="">
                    <span class="top-text">直播带货脚本</span>
                    <img src="../../assets/image/student/tag.png" alt="">
                </div>
                <div class="script-content" id="scriptContent">
                    <!-- <el-scrollbar>
                        <div class="script-item-content" id="scriptItemContent">
                            <div class="script-item" v-for="(item,index) in scriptList" :key="index">
                                <div :class="currentIndex >= index?'title-content active-title':'title-content'"
                                    :id="`parentTitle_${index}`" ref="parentTitle">
                                    <div class="title-left" v-if="index > 0">{{index+1}}</div>
                                    <div class="title-left" v-else>
                                        <i class="el-icon-right"></i>
                                    </div>
                                    <div class="title-right">{{item.title}}</div>
                                </div>
                                <div class="children-content">
                                    <div class="script-subItem" v-for="(subItem,subIndex) in item.content"
                                        :key="subIndex"
                                        :id="`subItem_${index}_${subIndex}`" :ref="`subItem_${index}_${subIndex}`"
                                        @click="changeStatus(index,subIndex)">
                                        <div class="subItem-time">{{subItem.begin_time}}</div>
                                        <span class="subItem-main" v-html="subItem.correct_content"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-scrollbar> -->
                  
                    <el-scrollbar class="script-inner">
                        <div class="script-title">{{modules}}</div>
                        <div v-html="highLight(currentContent)" class="inner-main" >{{currentContent}}</div>
                    </el-scrollbar>
                </div>
            </div>
             <div class="content-item left-content">
                <div class="top-title">
                    <img src="../../assets/image/student/tag_left.png" alt="">
                    <span class="top-text">学生答案情况</span>
                    <img src="../../assets/image/student/tag.png" alt="">
                </div>
                <div class="script-content" id="scriptContent">
                    <!-- <el-scrollbar>
                        <div class="script-item-content" id="scriptItemContent">
                            <div class="script-item" v-for="(item,index) in scriptList" :key="index">
                                <div :class="currentIndex >= index?'title-content active-title':'title-content'"
                                    :id="`parentTitle_${index}`" ref="parentTitle">
                                    <div class="title-left" v-if="index > 0">{{index+1}}</div>
                                    <div class="title-left" v-else>
                                        <i class="el-icon-right"></i>
                                    </div>
                                    <div class="title-right">{{item.title}}</div>
                                </div>
                                <div class="children-content">
                                    <div class="script-subItem" v-for="(subItem,subIndex) in item.content"
                                        :key="subIndex"
                                        :id="`subItem_${index}_${subIndex}`" :ref="`subItem_${index}_${subIndex}`"
                                        @click="changeStatus(index,subIndex)">
                                        <div class="subItem-time">{{subItem.begin_time}}</div>
                                        <span class="subItem-main" v-html="subItem.correct_content"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-scrollbar> -->
                  
                    <el-scrollbar class="script-inner">
                        <div class="script-title" >{{modules}}</div>
                        <div  class="inner-main">
                            <img v-show="showCorrect"  style="display:inline;padding-right: 10px;vertical-align: middle;" src="../../assets/image/correct.png">
                            <img v-show="showMistake"  style="display:inline;padding-right: 10px;vertical-align: middle;" src="../../assets/image/mistake.png">
                            <div style="display: inline;vertical-align: middle;"  v-html="stuHighLight(answerCurrentContent)">
                                <span>{{answerCurrentContent}}</span>
                            </div>
                        </div>
                       
                    </el-scrollbar>
                </div>
            </div>
        </div>
        <div class="progress-content" >

            <div class="progress-inner" ref="name" style="position: relative;padding: 0 38px;" >
                <span class="drag-btn" id="btn1" ref="tu" style="position: absolute;z-index: 999;left: 38px;width: 3px;height: 75px;background: #4B45FF;"></span>
                <div class="progress-item" id="oneBox" v-for="(item,index) in scriptList" :key="index" >
                    <div class="item-time"  @click="chooseTime(index,$event,item)">{{item.start_time}}</div>
                    <div class="item-line" ></div>
                    <div class="item-line-bottom"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import StudentAnswer from "../../components/student/StudentAnswer";
    import {getStuScript,getTimeLive,getOneDetail} from '@/utils/apis'

    export default {
        name: "liveReplay",
        components: {
            StudentAnswer
        },
        data() {
            return {
                scriptList: [
                   
                ],
                showCorrect:false,
                showMistake:false,
                rightData: [
                    {
                        title: '累计观看人数',
                        value: '85227',
                        icon: '&#xe73c;',
                        color1: '#5550FB',
                        color2: '#2821FC'
                    },
                    {
                        title: '实时在线人数',
                        value: '3357',
                        time: '',
                        icon: '&#xe61c;',
                        color1: '#05B9B9',
                        color2: '#81E5E5'
                    },
                    {
                        title: '订单数',
                        value: '6357',
                        icon: '&#xe727;',
                         color1: '#5550FB',
                        color2: '#2821FC'
                    },
                    {
                        title: '销售额',
                        value: '3852',
                        icon: '&#xe74b;',
                        color1: '#FF3E6C',
                        color2: '#FE92AC'
                    },
                    {
                        title: '点赞数',
                        value: '7157',
                        icon: '&#xe71a;',
                        color1: '#13BEC6',
                        color2: '#2156EA'
                    },
                ],
                sendOption: {
                    singleHeight: 51,
                    hoverStop: true,
                    waitTime: 1500
                },
                currentIndex: 0,
                modules:'',
                answerIndex: 0,
                stateObj: {
                    index: -1,
                    subIndex: -1
                },
                timer: '',
                currentChildIndex: 0,
                currentContent: '',
                action:'',
                answerCurrentContent:'',
                studentInfo: {
                    studentContent: '',
                    right_keyword: '',
                },
                activities: [{
                    content: '活动按期开始',
                    timestamp: '2018-04-15'
                }, {
                    content: '通过审核',
                    timestamp: '2018-04-13'
                }, {
                    content: '创建成功',
                    timestamp: '2018-04-11'
                }],
                studentList: [],
                publicList: [2,2,2],
            }
        },
        mounted() {
            //  this.getScriptList()
            this.getTimeDate()

            
            
        },
        methods: {
           

            // changeStatus(index, subIndex) {
            //     if (this.stateObj.index != -1) {
            //         this.$refs[`subItem_${this.stateObj.index}_${this.stateObj.subIndex}`][0].classList.remove('isCurrent')
            //     }
            //     this.$refs.studentAnswer.cleanStatus()
            //     this.stateObj.index = index
            //     this.stateObj.subIndex = subIndex
            //     clearInterval(this.timer)
            //     this.$refs.studentAnswer.changeStatus()
            //     if (this.currentChildIndex > 0) {
            //         this.$refs[`subItem_${this.currentIndex}_${this.currentChildIndex - 1}`][0].classList.remove('isCurrent')
            //     } else {
            //         this.$refs[`subItem_${this.currentIndex}_${this.currentChildIndex}`][0].classList.remove('isCurrent')
            //     }
            //     this.$refs[`subItem_${index}_${subIndex}`][0].classList.add('isCurrent')
            //     this.$el.querySelector(`#subItem_${index}_${subIndex}`).scrollIntoView({
            //         behavior: "smooth",  // 平滑过渡
            //         block: "start"  // 上边框与视窗顶部平齐。默认值
            //     });
            // },
            // getScriptList() {
            //     getStuScript().then(res => {
            //         if (res.code == 200) {
            //             this.scriptList = res.data.correct
            //             this.studentList = res.data.student //学生答案情况
            //             this.publicList = res.data.public
            //             this.formData()
            //             // this.$nextTick(() => {
            //             //     this.currentChildIndex = 0
            //             //     this.timer = setInterval(() => {
            //             //         let parentLen = this.scriptList.length
            //             //         let currentLen = this.scriptList[this.currentIndex].content.length
            //             //         this.$el.querySelector(`#subItem_${this.currentIndex}_${this.currentChildIndex}`).scrollIntoView({
            //             //             behavior: "smooth",  // 平滑过渡
            //             //             block: "start"  // 上边框与视窗顶部平齐。默认值
            //             //         });
            //             //         this.$refs[`subItem_${this.currentIndex}_${this.currentChildIndex}`][0].classList.add('isCurrent')
            //             //         if (this.currentChildIndex > 0) {
            //             //             this.$refs[`subItem_${this.currentIndex}_${this.currentChildIndex - 1}`][0].classList.remove('isCurrent')
            //             //         }
            //             //         if (this.currentChildIndex == currentLen - 1) {
            //             //             if (this.currentIndex == parentLen - 1) {
            //             //                 clearInterval(this.timer)
            //             //             } else {
            //             //                 setTimeout(() => {
            //             //                     this.currentIndex++
            //             //                     this.$refs[`subItem_${this.currentIndex - 1}_${currentLen - 1}`][0].classList.remove('isCurrent')
            //        getTimeDate     //                 }, 900)
            //             //                 this.currentChildIndex = 0
            //             //             }
            //             //         } else {
            //             //             this.currentChildIndex++
            //             //         }
            //             //         this.value += this.step
            //             //     }, (res.data.correct[this.currentIndex].content[this.currentChildIndex].end_timestamp) * 1000)
            //             // })
            //         }
            //     })
            // },
            
            //第一次请求
            getData(){
                console.log(this.scriptList[0],'33333')
                   let params = {
                    user_id:JSON.parse(localStorage.getItem("studentId")),
                    train_id: JSON.parse(localStorage.getItem("train_id")),
                    // train_id: 36,
                    module:this.scriptList[0].module,
                    script_art_word_id:this.scriptList[0].script_art_word_id,
                    goods_script_admin_id:this.scriptList[0].goods_script_admin_id,
                    is_children:this.scriptList[0].is_children,
                    goods_script_children_admin_id:this.scriptList[0].goods_script_children_admin_id,
                    lucky_bag_script_id:this.scriptList[0].lucky_bag_script_id,
                };
                  getOneDetail(params)
                    .then((res) => {
                        if (res.code == 200) {
              
                            this.currentContent = res.data.data.content.script_content
                            this.searchWord = res.data.data.content.key_word
                            this.answerCurrentContent = res.data.data.other_data.answer_data
                            this.rightData[0].value = res.data.data.other_data.in_online_number
                            this.rightData[1].value = res.data.data.other_data.online_total_number
                            this.rightData[2].value = res.data.data.other_data.order_num
                            this.rightData[3].value = res.data.data.other_data.sales
                            this.rightData[4].value = res.data.data.other_data.like

                            if(res.data.data.content.script_art_word_id){
                                this.modules = '脚本话术:'
                            }else if(res.data.data.content.lucky_bag_script_id){
                                 this.modules = '脚本福袋:'
                            }
                            

                            if(res.data.data.content.script_type){
                                if(res.data.data.content.script_type ==1){
                                     this.modules = '商品讲解:'
                                }else if(res.data.data.content.script_type ==2){
                                     this.modules = '优惠券:'
                                }else if(res.data.data.content.script_type ==3){
                                     this.modules = '商品问答:'
                                }else if(res.data.data.content.script_type ==4){
                                    this.modules = '产品卖点介绍:'
                                }
                            }
                            //  if(res.data.data.content.goods_script_admin_id){
                            //    if(res.data.data.content.action==1||res.data.data.content.action==2){
                            //        this.showCorrect = true
                            //        this.showMistake =false
                            //    }else {
                            //         this.showCorrect = false
                            //         this.showMistake =true
                               
                            //    }
                            // }else
                            //  if(res.data.data.content.goods_script_admin_id==undefined){
                            //        this.showCorrect = false
                            //        this.showMistake =false
                            // }
                        }
                    })
                    .catch((err) => {
                        console.error("err", err);
                    });
            },

            getTimeDate(){
              
                let params = {
                    user_id:JSON.parse(localStorage.getItem("studentId")),
                    train_id: JSON.parse(localStorage.getItem("train_id")),
                };
                getTimeLive(params)
                    .then((res) => {
                        if (res.code == 200) {
                            console.log(res.data,111)
                            this.scriptList = res.data
                            this.getData()
                        
                        }
                    })
                    .catch((err) => {
                        console.error("err", err);
                    });
            },


            goToDetail() {
            let routeUrl = this.$router.resolve({
                name: "dataCenterReplayData",
                path: "../teacher/dataCenter/ReplayData",
                query: {
                    user_id: JSON.parse(localStorage.getItem("studentId")), 
                    train_id: JSON.parse(localStorage.getItem("train_id"))
                },
            });
            window.open(routeUrl.href, "_blank");

            },
            //直播带货脚本检测关键词
            highLight(currentContent){
                
                let Content = currentContent
                // 如果标题中包含，关键字就替换一下
                if(Content.includes(this.searchWord)){
                        Content = Content.replace(
                        this.searchWord, 
                        // 这里是替换成html格式的数据，最好再加一个样式权重，保险一点
                        '<font style="color:red!important;">'+ this.searchWord +'</font>'
                    )
                    return Content
                }
                // 不包含的话还用这个
                else{
                    return Content
                }
                
            },
            //学生答案情况检测关键词
             stuHighLight(answerCurrentContent){
               if(answerCurrentContent ==undefined){
                      this.showCorrect = false
                       this.showMistake = false   
                
               }else if(answerCurrentContent !=undefined){                  
                    let Content = answerCurrentContent
                    let judge = "不在正确"
                    let judge_Mistake = "错误"
                // 如果标题中包含，关键字就替换一下
                    if(Content.includes(judge)){
                       this.showCorrect = false
                       this.showMistake = true   
          
                    }else if(Content.includes(judge_Mistake)){
                        this.showCorrect = false
                       this.showMistake = true   
                    }else{
                        this.showCorrect = true
                       this.showMistake = false   
                    }
                    if(Content.includes(this.searchWord)){
                            Content = Content.replace(
                            this.searchWord, 
                            // 这里是替换成html格式的数据，最好再加一个样式权重，保险一点
                            '<font style="color:red!important;">'+ this.searchWord +'</font>'
                        )
                        return Content
                    }
                    // 不包含的话还用这个
                    else{
                        return Content
                    }
                   
               }
                
            },


            chooseTime(index,e,item) {
                this.currentIndex = index
                let tdiv = this.$refs.tu;
                const rect=e.target.getBoundingClientRect()//获取点击的dom的位置
                let drleft = rect.left+13
                let scroLeft = this.$refs.name.scrollLeft //获取滚动条距离
                tdiv.style.left = drleft+scroLeft+"px";

                if(item.module ==1){
                    this.modules = '脚本话术:'
                }else if(item.module ==3){
                     this.modules = '脚本福袋:'
                }

                 let params = {
                    user_id:JSON.parse(localStorage.getItem("studentId")),
                    train_id: JSON.parse(localStorage.getItem("train_id")),
                    module:item.module,
                    script_art_word_id:item.script_art_word_id,
                    goods_script_admin_id:item.goods_script_admin_id,
                    is_children:item.is_children,
                    goods_script_children_admin_id:item.goods_script_children_admin_id,
                    lucky_bag_script_id:item.lucky_bag_script_id,
                };
                

                 getOneDetail(params)
                    .then((res) => {
                        if (res.code == 200) {
        
                            this.currentContent = res.data.data.content.script_content
                            this.searchWord = res.data.data.content.key_word
                            this.answerCurrentContent = res.data.data.other_data.answer_data
                            this.rightData[0].value = res.data.data.other_data.in_online_number
                            this.rightData[1].value = res.data.data.other_data.online_total_number
                            this.rightData[2].value = res.data.data.other_data.order_num
                            this.rightData[3].value = res.data.data.other_data.sales
                            this.rightData[4].value = res.data.data.other_data.like

                            // if(res.data.data.content.goods_script_admin_id){
                            //    if(res.data.data.content.action==1||res.data.data.content.action==2){
                            //        this.showCorrect = true
                            //        this.showMistake =false
                            //    }else {
                            //         this.showCorrect = false
                            //         this.showMistake =true
                               
                            //    }
                            // }else if(res.data.data.content.goods_script_admin_id==undefined){
                            //        this.showCorrect = false
                            //        this.showMistake =false
                            // }
                            // console.log(res.data.data.content.action,'654')

                            if(res.data.data.content.script_type){
                                if(res.data.data.content.script_type ==1){
                                     this.modules = '商品讲解:'
                                }else if(res.data.data.content.script_type ==2){
                                     this.modules = '优惠券:'
                                }else if(res.data.data.content.script_type ==3){
                                     this.modules = '商品问答:'
                                }else if(res.data.data.content.script_type ==4){
                                    this.modules = '产品卖点介绍:'
                                }
                            }
                        }
                    })
                    .catch((err) => {
                        console.error("err", err);
                    });
  
            },
            // formData() {
            //     this.currentContent = this.scriptList[this.currentIndex].correct_content
            //     this.studentInfo.studentContent = this.studentList[this.currentIndex].student_content
            //     this.studentInfo.right_keyword = this.studentList[this.currentIndex].right_keyword
            //     this.rightData[0].value = this.publicList[this.currentIndex].total_num
            //     this.rightData[1].value = this.publicList[this.currentIndex].enter_and_leave_num
            //     this.rightData[2].value = this.publicList[this.currentIndex].now_num
            //     this.rightData[3].value = this.publicList[this.currentIndex].deal_num
            //     this.rightData[4].value = this.publicList[this.currentIndex].sales
            //     this.rightData[5].value = this.publicList[this.currentIndex].like_num
            //     this.rightData[1].time = this.scriptList[this.currentIndex].begin_time + '-' + this.scriptList[this.currentIndex].end_time
            //     this.rightData[1].is_leave = this.publicList[this.currentIndex].is_leave
            // }
        }
    }
</script>

<style scoped lang="scss">
    .live-content {
        height: 100%;
        width: 100%;
        background: #070932;
        position: relative;
        display: flex;
        flex-direction: column;
    }

    .tag-item {
        position: absolute;
        top: -22px;
        left: 20px;
        z-index: 1;

        .item-content {
            box-shadow: 0px 0px 21px 0px rgba(0, 3, 85, 0.66);
            position: relative;

            img {
                width: 113px;
                height: 131px;
            }

            .item-text {
                position: absolute;
                width: 40px;
                top: 34px;
                left: 38px;
                color: #fff;
                font-size: 18px;
                font-weight: bold;
                word-break: break-all;
                cursor: pointer;
            }
        }
    }

    .resume-title {
        color: #fff;
        font-size: 30px;
        font-weight: 700;
        text-align: center;
        line-height: 90px;
        position: relative;

        &:before {
            content: '';
            width: 391px;
            height: 75px;
            background: url("../../assets/image/student/title_left.png") no-repeat;
            position: absolute;
            left: calc(50% - 391px - 90px - 10px);
            top: 50%;
            transform: translateY(-50%);
        }

        &:after {
            content: '';
            width: 391px;
            height: 75px;
            background: url("../../assets/image/student/title_right.png") no-repeat;
            position: absolute;
            right: calc(50% - 391px - 90px - 10px);
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .main-content {
        flex: 1;
        height: 1%;
        margin: 20px;
        display: flex;

        .content-item {
            border-radius: 20px;
        }

        .left-content {
            margin-right: 20px;
            width: 50%;
            background: #0C0E3F;
            display: flex;
            flex-direction: column;
            position: relative;

            .top-title {
                margin-top: 42px;
                text-align: center;

                img {
                    width: 41px;
                    height: 15px;
                }

                .top-text {
                    margin: 0 21px;
                    font-size: 24px;
                    color: #FFFFFF;
                }
            }

            .script-content {
                margin: 51px 0 20px;
                flex: 1;
                display: flex;
                flex-direction: column;
                color: #fff;
                overflow: hidden;

                ::v-deep .el-scrollbar {
                    flex: 1;
                    height: 1%;

                    .el-scrollbar__wrap {
                        overflow-x: hidden;

                        .el-scrollbar__view {
                            height: 100%;
                            // margin-left: 70px;
                        }
                    }

                    .el-scrollbar__bar.is-vertical {
                        .el-scrollbar__thumb {
                            width: 8px;
                            background: #4B45FF;
                        }
                    }
                }

                .script-item {
                    position: relative;
                    cursor: grab;

                    .title-content {
                        display: flex;
                        align-items: center;
                        font-size: 18px;

                        .title-left {
                            width: 26px;
                            height: 26px;
                            background: #BFBFBF;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }

                        .title-right {
                            margin-left: 12px;
                        }
                    }

                    .active-title {

                        .title-left {
                            background: #F49D19;
                        }
                    }

                    .children-content {
                        margin-left: 12px;
                        border-left: 1px dashed #444797;
                        padding: 20px 0 20px 28px;

                        .script-subItem {
                            /*margin-top: 20px;*/
                            width: 90%;
                            word-break: break-all;
                            line-height: 40px;
                            opacity: 0.3;
                            padding: 10px;

                            .subItem-time {
                                position: absolute;
                                left: -75px;
                                text-align: right;
                                width: 60px;
                            }
                        }
                    }
                }

                .script-title {
                    // margin-left: 60px;
                    font-size: 18px;
                }

                .script-inner {
                    /*height: 50%;*/
                    width: calc(100% - 60px);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 24px;
                    text-align: left;
                    margin-left: 30px;

                    ::v-deep .el-scrollbar__wrap {
                        width: 100%;
                        margin-right: -40px !important;

                        .el-scrollbar__view {
                            height: unset;
                        }
                    }

                    .inner-main {
                        // width: 100%;
                        // margin-top: 60px;
                        margin-left: 100px;
                        margin-top: 30px;
                    }
                }
            }
        }
    }

    .data-content {
        display: flex;
        margin: 10px 20px;


        .data-content-item {
            margin-right: 21px;
            width: calc(100% / 5);
            color: #FFFFFF;
            border-radius: 20px;
            min-height: 80px;
            z-index: 1;
            height: 143px;
            position: relative;

            &:last-child {
                margin-right: 0;
            }

            .middle-box {
                display: flex;
                flex-direction: column;
                align-items: center;
                flex: 1;
                margin: 20px 0;
                height: calc(100% - 40px);

                .top-item {
                    font-size: 16px;
                    opacity: 0.8;
                }

                .middle-item {
                    font-size: 30px;
                    font-weight: bold;
                    color: #FFFFFF;
                    margin-top: 20px;
                    display: flex;

                    i {
                        font-size: 32px;
                        margin-right: 10px;
                        color: #FF3E6C;
                    }
                }

                .bottom-item {
                    font-size: 16px;
                    // font-weight: bold;
                    margin-top: 20px;
                }
            }

            .icon-box {
                position: absolute;
                top: 22px;
                left: 20px;

                i {
                    font-size: 48px;
                }
            }

            .shadow-icon {
                position: absolute;
                top: 0;
                right: 0;

                i {
                    font-size: 140px;
                    opacity: 0.1;
                }
            }
        }
    }

    .shadow-3 {
        background: rgba(0, 0, 0, .3);
    }

    .isCurrent {
        opacity: 1 !important;
        font-size: 16px;
        color: #12C4C4;
        background: rgba(10, 8, 73, 0.5);
    }

    .progress-content {
        width: 100%;
        height: 100px;
        background: #0C0E3F;
        color: #fff;
        display: flex;

        .progress-inner {
            display: flex;
            width: 100%;
            overflow-x: auto;

            .progress-item {
                flex: 1;
                flex-basis: auto !important;
                flex-shrink: 0;
                flex-grow: 0;
                position: relative;
                margin-top: 20px;
                min-width: 100px;

                .item-line {
                    height: 18px;
                    width: 1px;
                    background: #F7F8FC;
                    top: 10px;
                }

                .item-line-bottom {
                    width: 100%;
                    position: absolute;
                    border-color: inherit;
                    background: #F7F8FC;
                    height: 2px;
                    top: 44px;
                    left: 0;
                    right: 0;
                }

                .item-time {
                    margin-left: -15px;
                    margin-bottom: 10px;
                    cursor: pointer;
                }

                &:first-child {
                    padding-left: 40px;

                    .item-line-bottom {
                        left: 40px;
                    }
                }

                &:last-child {
                    .item-line-bottom {
                        display: none;
                    }
                }
            }
        }
    }

    .color-word {
        color: #4B45FF;
    }

    ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-thumb { //滑块部分
  border-radius: 5px;
  background-color: #4B45FF;
}
::-webkit-scrollbar-track { //轨道部分
  box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
  background: #1e2061;
  border-radius: 5px;

}
</style>